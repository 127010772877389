<template>
  <div class="hero-content__row row w-100 mx-0">
    <div class="col-xl-6">
      <div
        class="hero-content d-flex flex-column align-content-center align-content-xl-start justify-content-center h-100"
      >
        <div class="hero-content__desc">NFT Storage & Gallery</div>
        <h1 class="hero-content__title">
          the best place to Store your World class NFTs.
        </h1>
        <div class="hero-img d-flex d-xl-none">
          <img src="/images/hero-img.png" class="w-100 h-100" alt="#" />
        </div>
        <div v-if="!isAuthenticated && !isLoading">
          <button
            id="qsLoginHeroBtn"
            class="btn-custom btn-custom__white mx-auto mx-xl-0"
            @click.prevent="login"
          >
            Login
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-6 d-none d-xl-flex">
      <div class="hero-img w-100">
        <img src="/images/hero-img.png" class="w-100 h-100" alt="#" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "Hero",
  setup() {
    const { isAuthenticated, isLoading, user, loginWithRedirect, logout } =
      useAuth0();
    return {
      isAuthenticated,
      isLoading,
      user,
      login: () => {
        loginWithRedirect();
      },
      logout: () => {
        logout({
          returnTo: window.location.origin,
        });
      },
    };
  },
};
</script>
