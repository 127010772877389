<template>
  <div id="app" class="d-flex flex-column h-100">
    <nav-bar />
    <section class="main-block" v-bind:class="$route.name">
      <div class="container mx-auto">
        <error />
        <router-view class="row" />
      </div>
    </section>
    <footer class="footer">
      <div class="container flex-grow-1">
        <div class="footer-row">
          <div class="logo-item">
            <div class="logo-icon">
              <img src="/images/logo-icon.png" alt="#" />
            </div>
            <div class="logo-text">
              <img src="/images/logo-text.svg" alt="#" />
            </div>
          </div>
          <div class="footer-socials">
            <a href="https://twitter.com/spruceid" class="footer-socials__item">
              <img src="/images/twitter.svg" alt="#" />
            </a>
            <a
              href="https://github.com/spruceid/siwe-auth0-example"
              class="footer-socials__item"
            >
              <img src="/images/github.svg" alt="#" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import "@/assets/scss/index.scss";

export default {
  components: {
    NavBar,
  },
};
</script>
