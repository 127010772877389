
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "NavBar",
  setup(): unknown {
    const { isAuthenticated, isLoading, user, loginWithRedirect, logout } =
      useAuth0();
    return {
      isAuthenticated,
      isLoading,
      user,
      login: () => {
        loginWithRedirect();
      },
      logout: () => {
        logout({
          returnTo: window.location.origin,
        });
      },
    };
  },
};
