
import { useAuth0 } from "@auth0/auth0-vue";
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
  name: "profile",
  setup(): any {
    const { user, getAccessTokenSilently } = useAuth0();
    const nfts = ref();
    const getNfts = async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        const { data } = await axios.get("/api/nfts", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });
        nfts.value = data;
      } catch (e) {
        nfts.value = [];
      }
    };

    onMounted(() => {
      getNfts();
    });

    return {
      user,
      nfts,
    };
  },
};
